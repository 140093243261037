<script setup lang="ts">
import { useGameStore } from '~/stores/games.store';
import Button from '~/components/design-system/button.vue';
import { trackingMethods } from '~~/plugins/analytics.client';
import { useNuxtApp } from 'nuxt/app';
import Select from 'primevue/select';

const { $t } = useNuxtApp();
interface IFilterProps {
  vertical?: boolean;
}
interface IFilters {
  category: string | string[];
  provider: string;
  theme: string;
}

interface ITempProps {
  key: any;
  value: any;
}

const route = useRoute();
const router = useRouter();
const games = useGameStore();

const props = withDefaults(defineProps<IFilterProps>(), {
  vertical: false,
});
const emits = defineEmits(['closeModal']);
const base = reactive<IFilters>({
  category: '',
  provider: '',
  theme: '',
});
let filters = reactive<IFilters>({
  category: route.params.category,
  provider: route.query.provider,
  theme: route.query.theme,
});

const categories: globalThis.ComputedRef<string> = computed(() => {
  return applyUppercase('categories');
});

const providers: globalThis.ComputedRef<string> = computed(() => {
  return applyUppercase('providers');
});

function applyUppercase(element: string): string {
  return element.charAt(0).toUpperCase() + element.slice(1);
}

function applyFilters() {
  const { category, provider, theme } = filters;
  trackingMethods.filter(category, provider, theme);
  router.push({
    path: `/${route.params.contentType}/${category || 'all'}`,
    query: {
      ...route.query,
      provider,
      theme,
    },
  });
  emits('closeModal');
}

function reset() {
  filters = base;
  router.push(`/${route.params.contentType}`);
  emits('closeModal');
}

function mapFilterStrings(data: any[], theme: string) {
  const temp: ITempProps[] = data.map((fil) => {
    return {
      key: $t(`${theme}.${fil.replaceAll(' ', '-').toLowerCase()}`),
      value: fil,
    };
  });
  return temp;
}
</script>
<template>
  <div class="flex" :class="props.vertical ? 'flex-col' : ''">
    <div class="px-2 py-1 w-full">
      <label class="m-2 ml-0 mb-2 text-black dark:text-white text-xs font-bold">
        {{ categories }}
      </label>
      <Select
        class="w-full category-filter mt-2"
        v-model="filters.category"
        :options="mapFilterStrings(games.returnCategories, 'vertical.category')"
        :placeholder="$t('select-option')"
        option-label="key"
        option-value="value"
      />
      <!-- $t(`category.${games.returnCategories}`) -->
    </div>
    <div class="px-2 py-1 w-full">
      <label class="m-2 ml-0 mb-2 text-black dark:text-white text-xs font-bold">
        {{ providers }}
      </label>
      <Select
        class="w-full category-filter mt-2"
        v-model="filters.provider"
        :options="mapFilterStrings(games.returnProviders, 'providers')"
        :placeholder="$t('select-option')"
        option-label="key"
        option-value="value"
      />
    </div>
    <div class="px-2 py-1 w-full">
      <label class="m-2 ml-0 mb-2 text-black dark:text-white text-xs font-bold">
        {{ $t('themes') }}
      </label>
      <Select
        class="w-full category-filter mt-2"
        v-model="filters.theme"
        :options="games.returnThemes"
        :placeholder="$t('select-option')"
      />
      <!-- TODO:
      eventually need to mapFilterStrings(games.returnThemes, 'themes') when multiple languages supported -->
    </div>
    <div
      class="grid grid-cols-2 gap-3 p-3 bg-light-200 dark:bg-dark-900 flex mt-2 capitalize w-full"
    >
      <Button class="w-full text-center" group="secondary" @click="reset()">
        <span class="w-full text-center">
          {{ $t('reset') }}
        </span>
      </Button>
      <Button class="w-full" @click="applyFilters()">
        <span class="w-full text-center capitalize">
          {{ $t('apply') }}
        </span>
      </Button>
    </div>
  </div>
</template>
